import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['sidebar', 'burger', 'link'];

  connect() {
    this.stopTransitionOnLoad()
  }

  toggleSidebar(event) {
    this.sidebarTarget.classList.toggle('open');
    this.burgerTarget.classList.toggle('open');
  }

  async stopTransitionOnLoad(){
    await this._wait(1000);
    this.linkTargets.map(link => link.classList.remove('preload') );
  }

  _wait(ms = 0) {
     return new Promise(resolve =>  setTimeout(resolve, ms));
   }
}
