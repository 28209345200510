import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['flash'];

  connect() {
    this._startTimerToFadeAlert();
  }

  async _startTimerToFadeAlert() {
    await this._wait(2000);
    this.flashTarget.classList.add('fade-out');

    await this._wait(5000);
    this.flashTarget.remove();
  }

  async closeAlert() {
    this.flashTarget.remove();
  }

   _wait(ms = 0) {
     return new Promise(resolve =>  setTimeout(resolve, ms));
   }

}
