import { Controller } from 'stimulus';

export default class extends Controller {

    static targets = ['questionCategory', 'questionKind', 'questionOption', 'questionOptionsContainer',
                      'questionOptionsBooleansContainer', 'reasonLabel', 'questionKindsContainer',
                      'questionKindParams', 'reason', 'dynamicContainer'];
    static values = { category: String, kind: String }

    connect() {
      this._setInitialValuesAndForm();
    }

    checkKindAndCategory() {
      let state = event.target.name.replace('question', '').replace('[', '').replace(']', '');
      let value = event.target.value;
      state === 'kind' ? this.kindValue = value : this.categoryValue = value;
      this._updateForm();
    }

    _updateForm(category = this.categoryValue){
      this._removeOptions();
      if (category === 'scenario' || category === 'memory_checklist') {
        this.questionKindParamsTarget.value = 'explainer';
        this.questionKindsContainerTarget.classList.add('hidden');
        this.questionOptionsContainerTarget.classList.add('hidden');
        this.questionOptionsBooleansContainerTarget.classList.add('hidden');
        this.reasonTarget.innerHTML = 'Answer';
      } else {
        if (this.kindValue === 'true_false') {
          this._createBooleans();
          this.questionOptionsBooleansContainerTarget.classList.remove('hidden');
        } else {
          this._createOptions();
          this.questionOptionsContainerTarget.classList.remove('hidden');
        }
        this.questionKindParamsTarget.value = this.kindValue;
        this.reasonTarget.innerHTML = 'Explanation for Learning';
        this.questionKindsContainerTarget.classList.remove('hidden');
      }
    }

    _removeOptions() { if (this.hasDynamicContainerTarget) {this.dynamicContainerTarget.remove()} };

    _createOptions() {
      if (this.kindValue === '')  { return }; // Only on first load
      let optionsNeeded = this.kindValue === 'input' ? 1 : 4; // 1 answer or 1 answer and 4 options
      let i;
      let divOuterContainer = document.createElement("div");
      divOuterContainer.setAttribute("data-question-form-target", "dynamicContainer");
      for(i = 0; i < optionsNeeded; i++) {
        let divContainer = this._optionDiv(i);
        let hiddenField = this._optionHiddenCorrectField(i);
        let bodyField = this._optionBodyField(i);

        divContainer.appendChild(hiddenField);
        divContainer.appendChild(bodyField);
        divOuterContainer.appendChild(divContainer);
      }

      this.questionOptionsContainerTarget.insertAdjacentElement('beforeend', divOuterContainer);
    }

    _createBooleans() {
      if (this.kindValue === '')  { return }; // Only on first load
      let i;
      let divOuterContainer = document.createElement("div");
      divOuterContainer.classList.add('space-y-3', 'mb-4', 'mt-6');
      divOuterContainer.setAttribute("data-question-form-target", "dynamicContainer");

      let textElement = document.createElement("h4");
      textElement.innerHTML = 'The correct answer to this question is:';
      divOuterContainer.appendChild(textElement);
      let boolean_array = ['true', 'false'];

      boolean_array.forEach((boolean) => {
        let divContainer = this._booleanDiv();
        let booleanRadio = this._booleanRadioField(boolean);
        let labelRadio = this._booleanLabelField(boolean);

        divContainer.appendChild(booleanRadio);
        divContainer.appendChild(labelRadio);
        divOuterContainer.appendChild(divContainer);
      });

      this.questionOptionsBooleansContainerTarget.insertAdjacentElement('beforeend', divOuterContainer);
    }

    _booleanDiv() {
      let div = document.createElement("div");
      div.classList.add('ekq-radio');
      return div
    }

    _booleanRadioField(boolean) {
      let field = document.createElement("input");
      field.setAttribute("id", `question_true_false_option_${boolean}`);
      field.setAttribute("type", "radio");
      field.setAttribute("name", `question[true_false_option]`);
      field.setAttribute("value", boolean);
      return field;
    }

    _booleanLabelField(boolean) {
      let label = document.createElement("label");
      label.setAttribute("for", `question_true_false_option_${boolean}`);

      label.innerHTML = boolean;
      return label;
    }

    _optionDiv(index) {
      let div = document.createElement("div");
      div.classList.add('my-6');
      let hFour = document.createElement("h4");
      hFour.innerHTML = index === 0 ? 'Answer' : 'Multiple Choice Option';
      div.appendChild(hFour);
      return div;
    }

    _optionHiddenCorrectField(index) {
      let field = document.createElement("input");
      field.setAttribute("type", "hidden");
      field.setAttribute("name", `question[question_options_attributes][${index}][correct]`);
      field.setAttribute("value", index === 0 ? true : false);
      return field;
    }

    _optionBodyField(index) {
      let body = document.createElement("input");
      body.setAttribute("type", "text");
      body.setAttribute("required", true);
      body.setAttribute("name", `question[question_options_attributes][${index}][body]`);
      return body;
    }

    _setInitialValuesAndForm() {
      let category;
      let kind;

      this.questionCategoryTargets.map(targ => {category = targ.checked ? targ.value : ''})
      this.questionKindTargets.map(targ => {kind = targ.checked ? targ.value : ''})
      this.categoryValue, this.kindValue = category, kind;

      if (['scenario', 'useful', 'essential', 'memory_checklist'].includes(category)) {
        this._updateForm(category);
      }
    }

}
