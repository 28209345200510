import { Controller } from 'stimulus';

export default class extends Controller {

    static targets = ['dragArea', 'input'];

    connect() {
      this._initializeDragArea();
    }

    _initializeDragArea() {
      this.dragAreaTarget.addEventListener('dragenter', () => {
        this.dragAreaTarget.classList.add('event-dragenter');
      });

      this.dragAreaTarget.addEventListener('dragleave', () => {
        this.dragAreaTarget.classList.remove('event-dragenter');
      });

      this.inputTarget.addEventListener('change', () => {
        let fileName = this.inputTarget.files[0].name;

        this.dragAreaTarget.classList.remove('event-dragenter');
        this.dragAreaTarget.classList.add('event-drop');
        this.dragAreaTarget.setAttribute('data-filename', fileName);
      });

    }

}
