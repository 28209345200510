import { Controller } from "stimulus";
import Sortable from 'sortablejs';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["draggableComponent", "positionNumber"]

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.draggable', // element that initiates drag
      onStart: this.start.bind(this),
      onEnd: this.end.bind(this),
      swapThreshold: 1,
      animation: 150
    })
  }

  start(event) {
    this._animateDrag('grab', 'grabbing');
  }

  end(event) {
    this._animateDrag('grabbing', 'grab');

    let id = event.item.dataset.questionId;
    let data = new FormData();
    let number = event.newIndex + 1;
    data.append('position', number);
    data.append('course_id', event.item.dataset.courseId);

    Rails.ajax({
      url: this.data.get('url').replace(':id', id),
      type: 'PATCH',
      data: data,
      datatype: 'json',
      success: (data) => {
        const targets = [...this.positionNumberTargets];
        const questions = data.questions;
        questions.map((element, index)=>{
          let position = element.position;
          let match = targets.find(e => e.id == element.id);
          match.innerHTML = this._toOrdinalSuffix(position);
        })
      },
      error: (data) => {
        let errorContainer = document.querySelector('#errorContainer');
        errorContainer.classList.remove('hidden');
        let error = "Something went wrong with the drag and drop tool, please contact the development team.";
        errorContainer.innerHTML = error;
      }
    })
  }

  _animateDrag(removeClass, addClass) {
    let targets = this.draggableComponentTargets;

    for (let i = 0; i < targets.length; i++) {
      targets[i].classList.remove(removeClass);
      targets[i].classList.add(addClass);
    }
  }

  _toOrdinalSuffix(num) {
    const int = parseInt(num),
      digits = [int % 10, int % 100],
      ordinals = ['st', 'nd', 'rd', 'th'],
      oPattern = [1, 2, 3, 4],
      tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3];
  };
}
