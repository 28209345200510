import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['eusQuestions', 'memQuestions', 'eusQuestionsTab', 'memQuestionsTab'];

  switchTab() {
    const currentTab = this.data.get('current-tab');
    const id = event.currentTarget.id;
    id === currentTab ? null : this._switchViews(id);
  }

  _switchViews(id) {
    this.eusQuestionsTarget.classList.toggle('hidden');
    this.memQuestionsTarget.classList.toggle('hidden');

    this.eusQuestionsTabTarget.classList.toggle('ekq-active-tab');
    this.memQuestionsTabTarget.classList.toggle('ekq-active-tab');
    this.data.set('current-tab', id)
  }
}
